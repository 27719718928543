@import "../../../styles/variables";

.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17vh 22vw;
  height: 100vh;
  width: 100vw;

  .content {
    display: flex;
    width: 100%;
    min-width: 500px;
    max-width: 800px;
    background-color: $white-color;
  }
}
