$background-color: #f0f2f5;
$white-color: #fff;
$black-color: #000;
$dark-color: #333;
$error-color: #f5222d;
$border-color: #f0f0f0;
$header-color: #001528;

:export {
  background: $background-color;
  white: $white-color;
  black: $black-color;
  dark: $dark-color;
  error: $error-color;
  border: $border-color;
  header: $header-color;
}
