@import "../../../styles/variables";

.header {
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  background-color: $white-color;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;

    .switch {
      margin-left: 95px;
    }
  }
}

.moonIcon {
  height: 15px;
  width: 15px;
  margin-top: 3px;
}

.sunIcon {
  height: 17px;
  width: 17px;
}
