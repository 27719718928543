@import "../../styles/variables";

.layout {
  height: fit-content;
  width: 100%;
  padding: 24px;

  .content {
    margin-top: 16px;
    padding: 24px;
  }
}
