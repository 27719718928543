.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form {
    max-width: 350px;
    width: 100%;
    margin-top: 32px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
